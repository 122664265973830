@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300;400;500;600;700&display=swap');

.Container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  /* padding-top: 4%; */
  padding-bottom: 2%;
  align-text: center;

}

/* .Container hr {
  position: absolute;
  left: 0;
  width: 124px;
  border: 2px solid #da70d6;
} */

.Header {
  font-size: 50px;
  font-family: 'Signika', sans-serif;
  font-weight: 700;
  color: #fff;
  align-items: center;
  align-text: center;
  z-index:99;
}

@media (max-width: 767px) {
  /* .Container hr {
    position: absolute;
    left: 0;
    width: 40px;
    border: 2px solid #da70d6;
  } */
}

@media (min-width: 1600px) { 

  .Header {
    font-size: 100px;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    color: #fff;
    align-items: center;
    align-text: center;
    z-index:99;
  }
}