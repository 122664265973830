@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans+Condensed&display=swap');

.ContainerIdiomas {
 position: relative;

}

.SelectedLanguage {
  /* position: absolute; */
  /* margin-top: 128px; */
  padding-left:10px;
  text-decoration: none;
  color: #fff;
  font-family: 'Sofia Sans Condensed', sans-serif;
  font-size: 16px;
  font-weight: 200;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  line-height: 2;
  width: 100%;

}



.SelectedLanguage:before {
  content: '';
  display: block;
  width: 28px;
  height: 28px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('./../../media/flags/globe.svg');
}

.SelectedLanguage:hover .UlLanguage{
  display: block;
}

.SelectedLanguage:hover {
  color: #f24e29;
}
.SelectedLanguage:hover:before {
  background-image: url('./../../media/flags/globe2.svg');
}

.UlLanguage {
  text-decoration: none;
  color: #000;
  font-family: 'Sofia Sans Condensed', sans-serif;
  font-size: 22px;
  font-weight: 400;
  position: absolute;
  top: 100%;
  margin: 0;
  padding: 0;
  /* bottom: 100%; */
  margin-top: 15px;
  background-color: #fff;
  border: 1px solid #f8f8f8;
  box-shadow: 0px 1px 10px rgba(0,0,0,0.2);
  border-radius: 5px;
  /* visibility: hidden; */
  /* display:none */
}

.LiLanguage {
  list-style-type: none;
  display: flex;
  justify-content: space-between;

}

.ALanguage {
  width: 128px;
  display: block;
  padding: 5px 10px;
  text-decoration: none;
}

.ALanguage:before {
  content: '';
  display: inline-block;
  width: 28px;
  height: 28px;
  margin-right: 10px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('./../../media/flags/us.svg');
}

.ALanguage:hover {
  background-color: #f2f2f2;
}

.SpLanguage:before {
  background-image: url('./../../media/flags/ar.svg');
}

.EnLanguage:before {
  background-image: url('./../../media/flags/us.svg');
}

.PoLanguage:before {
  background-image: url('./../../media/flags/br.svg');
}