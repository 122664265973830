@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&display=swap');

.MarcoCard {
  position: relative;
  width: 300px;
  min-height: 250px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  /* border: 5px solid #fff; */
  box-shadow:  0px -22px 26px #0e0e0e,
  0px 22px 26px #2c2c2c;
  background: linear-gradient(315deg, #1a1a1a, #1f1f1f);
  cursor: pointer;
  transform-style: preserve-3d;
  transition: 250ms;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0));
}

.MarcoCard.flip {
  --rotate-y: 180deg;
}

.MarcoCard .FrontCard,
.MarcoCard .BackCard {
  position: absolute;
  /* padding: 1rem; */
  /* height: 150px;
  width: 150px; */
  backface-visibility: hidden;
}

.MarcoCard .BackCard {
  transform: rotateY(180deg);
  padding: 10px;
}


.ImageProject {
  position: relative;
  height: 100%;
  width: auto;
  /* border: 1px solid #1f1f1f; */
  /* box-shadow: inset 0 0 0 rgba(255, 255, 255, 0.9); */
  }
.TitleProject {
  font-family: 'Alegreya Sans SC', sans-serif;
  font-size: 24px;
  font-weight: 600;
  margin: 10px;
  color: #fff;
  }

  .MarcoTextFront {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .TextProject {
    font-family: 'Alegreya Sans', sans-serif;
    font-size: 22px;
    font-weight: 400;
    color: #fff;
  }

  .IconProject {
    height: 35px;
  }
  
  .InfoProject {
    font-family: 'Roboto Mono', monospace;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
  }

  .MarcoButton {
    display: flex;
    justify-content: center;
    border-radius: 30%;
    gap: 10px;
  }
  .Button {
    border: 1px solid #000;
    border-radius: 2em;
    padding: 10px;
    background-color: #fbb117;
    cursor: zoom-in;
  }
  .Button:hover {
    transform: translateY(-5px);
    animation: bounce 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    box-shadow: 0px 8px #241c15;
  }
  @keyframes bounce {
    0% {
      transform: translateY(-5px);
    }
    50% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(-2px);
    }
  }




