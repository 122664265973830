.Container {
  height: 230vh;
  width: auto;
  /* background-color: */
  background: #6A5ACD;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding-left: 20px;
 
}

.EscaleraContainer {
  height: inherit;
  width: inherit;
  /* background-color: */
  background: #6A5ACD;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  padding-left: 20px;
  padding-top: 25px;
  padding-bottom: 80px;

}

.SeparatorEscImg {
  position: relative;
  /* height: 100%; */
  width: 100%;
  z-index: 90;
}



@media (min-width: 320px) and (max-width: 480px) {

  .Container {
    height: 250vh;
    width: auto;
    /* background-color: */
    background: #6A5ACD;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding-left: 20px;
  
  }

  .EscaleraContainer {
    height: inherit;
    width: inherit;
    /* background-color: */
    background: #6A5ACD;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    padding-left: 20px;
    padding-top: 25px;
    /* padding-bottom: 200px; */
  
  }

}



@media (max-height: 667px) {
  .Container {
    height: 250vh;
    width: auto;
    /* background-color: */
    background: #6A5ACD;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding-left: 20px;
  
  }
}

@media (min-width: 1600px) { 

  .Container {
    height: 180vh;
    width: auto;
    /* background-color: */
    background: #6A5ACD;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding-left: 20px;
  }

  .EscaleraContainer {
    height: inherit;
    width: inherit;
    /* background-color: */
    background: #6A5ACD;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    padding-left: 20px;
    padding-top: 125px;
    padding-bottom: 80px;
  
  }
}