@import url('https://fonts.googleapis.com/css2?family=Signika:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin+Sketch&display=swap');

.Container {
  height: 100vh;
  width: auto;
  background: linear-gradient(to bottom, #210002, #0a0640);
  /* #050608 */
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;

}

.Presentation {
  position: absoulute;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 1rem;

}

.Titulo {
  font-size: 4rem;
  font-family: 'Signika', sans-serif;
  color: #fff;
  z-index: 2;
  position: absolute;

}

.Titulo2 {
  position: absolute;
  bottom: 320px;
  display:flex;
  z-index: 2;
  font-family: 'Signika', cursive;
  color: #fff;
  font-size: 1.5rem;
  /* font-weight: 100; */
  justify-content: center;
}

.Titulo span {
  font-size: 4rem;
  color: #fff;
  z-index: 2;
  /* color: pink; */
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #fff;
  animation: shake 3s ease-out infinite;

}
.Titulo span:hover {
  font-size: 4rem;
  color: #fff;
  z-index: 2;
  /* color: pink; */
  -webkit-text-fill-color: #fff;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #fff;

}

.ProfileImg {
  /* background-image: url{'./../../media/linkedin.jpg'}; */
}


.Hovered {
  font-weight: bold;
  position: relative;
  z-index: 1;
  display: inline-block;
}

.HoverReveal {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  top: -120px;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  border-radius:50%;
}

.HoverRevealImg {
  position: absolute;
  width: 300px;
  height: auto;
  /* top: 78px; */
  /* top: 0;
  left: 0;
  width: 100%;
  height: 100%; */
  object-fit: cover;
  box-shadow: 0 20px 5px 5px rgba(0, 0, 0, 0.2);
  animation: blob 5s ease-in-out infinite;
  transition: all 1s ease-in-out;
}
@keyframes blob {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}


.FlechasContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  animation: down 3s ease-out infinite;
  top: 0;
}
.FlechaHandler {
  width: 15%;
  transform: rotate(30deg);
}




@keyframes down {
  0% { transform: translate(0px, 2px);}
  25% { transform: translate(0px, -2px);}
  50% { transform: translate(0px, 2px);}
  75% { transform: translate(0px, -2px);}
  100% { transform: translate(0px, 2px);}

}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -1px) rotate(-0.5deg); }
  20% { transform: translate(-1px, 0px) rotate(0.5deg); }
  30% { transform: translate(1px, 1px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(0.5deg); }
  50% { transform: translate(-1px, 1px) rotate(-0.5deg); }
  60% { transform: translate(-1px, 1px) rotate(0deg); }
  70% { transform: translate(1px, 1px) rotate(-0.5deg); }
  80% { transform: translate(-1px, -1px) rotate(0.5deg); }
  90% { transform: translate(1px, 1px) rotate(0deg); }
  100% { transform: translate(1px, -1px) rotate(-0.5deg); }
}


@media (min-width: 768px) { 
  .Presentation {
    position: absoulute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 5rem;
  
  }

  .FlechaHandler {
    width: 5%;
    transform: rotate(10deg);
  }

  .HoverReveal {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }
  
}

@media (min-width: 960px) { 
  .HoverReveal {
    padding-right: 150px;
    justify-content: flex-end;
    align-items: center;
    top: -110px;
  }
  .Titulo2 {
    font-size: 1.5rem;
    bottom: 290px;
  }
}
@media (min-width: 1200px) { 
  .HoverReveal {
    padding-right: 250px;
    justify-content: flex-end;
    align-items: center;
    top: -80px;
  }
  .Titulo2 {
    font-size: 2rem;
    bottom: 290px;
  }
}

@media (min-width: 1600px) { 
  .HoverReveal {
    padding-right: 250px;
    justify-content: flex-end;
    align-items: center;
    top: -80px;
  }

  .Titulo {
    font-size: 6rem;
  }

  .Titulo span {
    font-size: 6rem;
    color: #fff;
    z-index: 2;
    /* color: pink; */
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
    animation: shake 3s ease-out infinite;
  }

  .Titulo span:hover {
    font-size: 6rem;
    color: #fff;
    z-index: 2;
    /* color: pink; */
    -webkit-text-fill-color: #fff;
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: #fff;
  }

  .Titulo2 {
    font-size: 4rem;
    bottom: 290px;
  }

  .HoverRevealImg {
    position: absolute;
    width: 600px;
    height: auto;
    /* top: 78px; */
    /* top: 0;
    left: 0;
    width: 100%;
    height: 100%; */
    object-fit: cover;
    box-shadow: 0 20px 5px 5px rgba(0, 0, 0, 0.2);
    animation: blob 5s ease-in-out infinite;
    transition: all 1s ease-in-out;
  }
}