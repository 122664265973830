@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans+Condensed&display=swap');

.Navbar {
  /* background-color: #da70d6; */
  height: 80px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: none;
  transition: background 500ms ease;
  opacity: 1;
   z-index: 19999;
}

.fixedNavbar {
  /* background-color: #da70d6; */
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: none;
  transition: background 500ms ease;
  opacity: 1;
   z-index: 19999;
}

.Navbar nav ul, .fixedNavbar nav ul {
  list-style: none;
  margin: 0;
  padding-right: 100px;
}

.Navbar nav ul li, .fixedNavbar nav ul li {
  display: inline-block;
  margin: 20px;
}

.Navbar nav ul li a, .fixedNavbar nav ul li a {
  text-decoration: none;
  color: #fff;
  font-family: 'Sofia Sans Condensed', sans-serif;
  font-size: 25px;
  font-weight: 700;
}

.Navbar a:hover, .fixedNavbar a:hover {
  /* border-bottom: 5px solid #afdcec; */
  /* border-bottom: 5px solid #da70d6; */
  color: #f24e29;
}

.Navbar .MobileNavigation, .fixedNavbar .MobileNavigation {
  display: none;
}


@media (max-width: 868px) {
  .Navbar .Navigation, .fixedNavbar .Navigation {
    display: none;
  }
  .Navbar .MobileNavigation, .fixedNavbar .MobileNavigation {
    display: flex;
    align-items: center;
    background-color: #8d2189;
  }

  .Navbar .MobileNavigation .Hamburger, .fixedNavbar .MobileNavigation .Hamburger {
    position: absolute;
    right: 5%;
    cursor: pointer;
  }

  .Navbar nav ul, .fixedNavbar nav ul {
     display: flex;
     flex-direction: column;
     position: absolute;
     top: 80px;
     right: 0;
     width: 100%;
     background: #ffaf00;
     border-top: 2px solid #3a0c39;
  }

  .Navbar nav ul li, .fixedNavbar nav ul li {
    padding-left: 50%;
  }

  }

  .StarsNavLogo {
    font-family: 'BenchNine';
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    margin: 0 auto;
    align-items: center;
    display: flex;
  }

  .StarLogoImg {
    border-radius: 50%;
    box-shadow: 0 0 16px 2px #f29544;
    animation: line1 16s linear infinite;
    z-index: -1;
    cursor: pointer;
  }

  .StarLogoImg2 {
    border-radius: 50%;
    box-shadow: 0 0 16px 2px #f29544;
    animation: line2 16s linear infinite;
    cursor: pointer;
  }

  @keyframes line1 {
    0% {transform: translateX(0) translateY(0);}
    25% {transform: translateX(55px) translateY(0);}
    50% {transform: translateX(23px) translateY(20px);}
    75% {transform: translateX(23px) translateY(20px);}
    100% {transform: translateX(0) translateY(0);}
  }

  @keyframes line2 {
    0% {transform: translateX(0) translateY(0);}
    10% {transform: translateX(4px) translateY(-10px);}
    20% {transform: translateX(5px) translateY(-10px);}
    30% {transform: translateX(6px) translateY(-10px);}
    40% {transform: translateX(7px) translateY(-8px);}
    50% {transform: translateX(8px) translateY(-8px);}
    60% {transform: translateX(7px) translateY(-8px);}
    70% {transform: translateX(6px) translateY(-8px);}
    80% {transform: translateX(5px) translateY(-4px);}
    90% {transform: translateX(4px) translateY(-4px);}
    100% {transform: translateX(0) translateY(0);}
  }

  .StarClickLogoImg {
    border-radius: 50%;
    box-shadow: 0 0 16px 2px #f29544;
    animation: orbit1 6s linear forwards;
  }

  .StarClickLogoImg2 {
    border-radius: 50%;
    box-shadow: 0 0 16px 2px #f29544;
    animation: orbit2 5s linear forwards;
  }

  @keyframes orbit1 {
    0% {
      transform: rotate(0deg) translateX(0px) rotate(0deg);
    }
    50% {
      transform: rotate(360deg) translateX(150px) rotate(-360deg);
    }
    100% {
      transform: rotate(720deg) translateX(0px) rotate(0deg);
    }
  }
  
  @keyframes orbit2 {
    0% {
      transform: rotate(0deg) translateX(0px) rotate(0deg);
    }
    50% {
      transform: rotate(360deg) translateX(150px) rotate(-360deg);
    }
    100% {
      transform: rotate(720deg) translateX(0px) rotate(0deg);
    }
  }

  @keyframes shine {
    0%  { box-shadow: 0 0 1px 1px #f29544;}
    25% { box-shadow: 0 0 2x 2px #f29544;}
    50% { box-shadow: 0 0 4px 4px #f29544;}
    75% { box-shadow: 0 0 6px 6px #f29544;}
    100% { box-shadow: 0 0 8px 8px #f29544;}
  }


@media (min-width: 1600px) { 

  
}