@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

.Section {

}

.Article {
  display: flex;
  flex-direction: row;
  position:relative; 
  transition:.4s;
  gap: 10px;
  margin-top: -41px;
}

.ArticleH2 {
  display:inline-block;
  font-weight:400;
  font-size: 40px;
  padding: 1rem 1rem 1rem 1.3125rem;
  box-shadow: -1px 1px, -2px 2px, -3px 3px, -4px 4px, -5px 5px, -6px 6px, -7px 7px, -8px 8px, -9px 9px, -10px 10px;   
  transition:.4s;

  font-size:1.6rem;
  text-transform:uppercase;
  align-self:center;
  text-align:center;
  cursor: pointer;

  /* background: hsl(176,85%,70%);
    color: hsl(176,45%,55%); */
    font: "Source Code Pro", monospace;
    /* background:whitesmoke; */
    -webkit-overflow-scrolling:touch;
    /* user-select:none; */
}

.ArticleH2:hover {
  transform:translate3d(5px,-5px,0);
  transition:.15s;
}
.ArticleH2:active {
  transition:0;
  transform:translate3d(-5px,5px,0);
  -webkit-filter:brightness(.7);
}

.ArticleH3 {
  font-weight:300;
  font-size: 14px;
  font-style:italic;
  align-self:center;
  text-align:center;
}