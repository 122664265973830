.Container {
  height: 560vh;
  width: auto;
  /* background-color: */
  background-color: #1d1d1d;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.ContainerProjects {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 60px;
}

.SeparatorEscImg {
  position: relative;
  /* height: 100%; */
  width: 100%;
  z-index: 90;
}



@media (min-width: 320px) and (max-width: 480px) {

  .Container {
    height: 630vh;
    width: auto;
    /* background-color: */
    background-color: #1d1d1d;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
  }
}

@media (max-height: 740px) {
  .Container {
    height: 740vh;
  }
}

@media (max-height: 667px) { 
  .Container {
    height: 760vh;
    width: auto;
    /* background-color: */
    background-color: #1d1d1d;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
  }
  
}



@media (min-width: 1024px) {
  
  .Container {
    height: 420vh;
    width: auto;
    /* background-color: */
    background-color: #1d1d1d;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    /* margin-top: -155px; */
  }
}

@media (min-width: 1600px) { 
  
  .Container {
    height: 420vh;
    width: auto;
    /* background-color: */
    background-color: #1d1d1d;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    /* margin-top: -300px; */
  }
}