.ContainerFormContact {
  height: 70vh;
  width: auto;
  background-color: #7c26b8;
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem; 
  margin-top: -5px;
  margin-left: auto;
  margin-right: auto; 
  
}

.TituloFormContact {
  padding-top:30px;
  font-weight: 700; 
  text-align: center;
  color: #ffffff;  
}

.FormFormContact {
  margin-left: auto;
margin-right: auto; 
max-width: 32rem; 
}

.ColumnaFormContact {
display: grid; 
grid-template-columns: repeat(1, minmax(0, 1fr)); 
gap: 1rem; 
}

.LabelContact {
position: absolute;
width: 1px;
height: 1px;
padding: 0;
margin: -1px;
overflow: hidden;
clip: rect(0, 0, 0, 0);
whiteSpace: nowrap;
borderWidth: 0; 
}

.InputContact {
padding-top: 0.5rem;
padding-bottom: 0.5rem; 
width: 100%; 
border-radius: 0.375rem; 
border-width: 1px;
resize: none;

}
.InputContact:focus {
  background-color: #1b123b;
  color: #fff;
}

.ButtonContact {
padding-top: 0.5rem;
padding-bottom: 0.5rem; 
padding-left: 1rem;
padding-right: 1rem; 
margin-top: 1rem; 
background-color: #3B82F6; 
color: #ffffff; 
border-radius: 0.375rem; 
}
.ButtonContact:hover {
background-color: #2563EB; 
}

.MarginTopContact {
  margin-top: 1rem; 
}

.MessageSubmit {
  font-size: ;
  font-weight: 700; 
  text-align: center;
  color: #ffffff;  
  padding-left: 1.25rem;
  padding-right: 1.25rem; 
}



@media (min-width: 768px) { 


  .ContainerFormContact {
    height: 70vh;
    width: auto;
    background-color: #7c26b8;
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem; 
    padding-top: 90px;
    margin-left: auto;
    margin-right: auto; 
    
  }

  .ColumnaFormContact {
  grid-template-columns: repeat(2, minmax(0, 1fr)); 
}
}

@media (min-width: 1600px) { 

  .ContainerFormContact {
    height: 70vh;
    width: auto;
    background-color: #7c26b8;
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem; 
    margin-top: -700px;
    margin-left: auto;
    margin-right: auto;    
  }

  .TituloFormContact {
    padding-top:30px;
    font-weight: 700; 
    text-align: center;
    color: #ffffff;
    font-size: 2.5rem;
  }
  
  .InputContact {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; 
    width: 100%;
    font-size: 1.5rem;
    border-radius: 0.375rem; 
    border-width: 1px;
    resize: none;
    }

  .MessageSubmit {
    font-size: 1.5rem;
    font-weight: 700; 
    text-align: center;
    color: #ffffff;  
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    }

  .FormFormContact {
    margin-left: auto;
    margin-right: auto; 
    max-width: 42rem; 
  }

  .ButtonContact {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; 
    padding-left: 1.5rem;
    padding-right: 1.5rem; 
    margin-top: 1.5rem; 
    background-color: #3B82F6; 
    color: #ffffff; 
    border-radius: 0.375rem; 
    font-size: 1.5rem;
    }
}