.ContainerGrogu{
  height: 60vh;
  width: 100%;
  background: linear-gradient(to bottom, #7c26b8, #0173B2);
  /* background-color: #7c26b8; */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.GroguBox {
  width: 360px;
  height: 200px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  justify-items: center;
  align-items: center;
  gap: 8px;

}

.Grogu {
  position: absolute;
  width: 50%;
}

.Imagengrogu {
  width: 75%;
  animation: moving 6s ease-in-out infinite alternate; 
}
.Imagengrogu2 {
  width: 75%;
}

.SpacerTop {
  height: 20px;
}

.Burbuja {

  height: 70px;
  width: 70px;
  /* border: 1px solid red; */
  border-radius: 10%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('./../../media/icons/email.svg');
  animation: moving 3s ease-in-out infinite alternate; 
}
.Burbuja2 {

  height: 70px;
  width: 70px;
  /* border: 1px solid red; */
  border-radius: 10%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('./../../media/icons/linkedin.svg');
  animation: moving 4s ease-in-out infinite alternate; 
}

.Burbuja3 {

  height: 70px;
  width: 70px;
  /* border: 1px solid red; */
  border-radius: 10%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('./../../media/icons/github2.png');
  animation: moving 5s ease-in-out infinite alternate; 
}

@keyframes moving{
  50%{
    transform: translateY(15%);
  }
}


@media (min-width: 768px) {

  .GroguBox {
    width: 760px;
    height: 200px;
  
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    justify-items: center;
    align-items: center;
    gap: 8px;
  
  }

  .Grogu {
    position: absolute;
    width: 50%;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    top: 20px; */
    /* align-items: center; */
  }

  .Imagengrogu {
    width: 75%;
  }

  .Burbuja {
    height: 100px;
    width: 100px;
  }
  .Burbuja2 {
    height: 100px;
    width: 100px;
  }
  .Burbuja3 {
    height: 100px;
    width: 100px;
  }

}