.ContainerPreescalera {

  height: 40vh;
  width: auto;
  background: #6A5ACD;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: -9px;
  }


  @media (min-width: 320px) and (max-width: 480px) {

    .ContainerPreescalera {

      height: 40vh;
      width: auto;
      background: #6A5ACD;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: -22px;
      }

  }

  @media (min-width: 768px) { 
  
    .ContainerPreescalera {
      height: 40vh;
      width: auto;
      background: #6A5ACD;
      position: relative;
    }
  }


  @media (max-height: 740px) {
    .ContainerPreescalera {
      height: 60vh;
    }
  }

@media (min-width: 1600px) { 

  .ContainerPreescalera {
    height: 60vh;
    margin-top: -22px;
  }

}