.ContainerPreprojects {

  height: 40vh;
  width: auto;
  background-color: #1d1d1d;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  }


  

  /* @media (min-width: 768px) { 
  
    .ContainerPreprojects {
      height: 40vh;
      width: auto;
      background-color: #1d1d1d;
      position: relative;
      margin-top: -5px;
    }
  } */
