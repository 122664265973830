@import url('https://fonts.googleapis.com/css2?family=Signika:wght@700&display=swap');

.Container {
  height: 100vh;
  width: auto;
  background-color: #0a0640;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: -5px

}

.Presentation {
  position: absoulute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 1rem;

}

.Titulo {
  font-size: 1rem;
  color: #fff;
  z-index: 2;
  font-family: 'Signika', sans-serif;
}

.seaContainer {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background: #0a0640;
  overflow: hidden;
}

.submarine__container {
  position: absolute;
  top: 25%;
  left: 50%;
  width: 400px;
  height: 200px;
  transform: translate(-50%, -50%);
}

.submarine__body {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250px;
  height: 80px;
  background: #d63f09;
  border-radius: 50px;
  transform: translate(-50%, -50%);
}

.submarine__propeller {
  position: absolute;
  left: 80%;
  top: 50%;
  width: 30px;
  height: 50px;
  transform: translate(0%, -50%);
  perspective: 600px;
}

.propeller__perspective {
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
  -webkit-animation: rotateInfinite 1s linear infinite;
          animation: rotateInfinite 1s linear infinite;
  cursor: pointer;
}

.submarine__propellerparts {
  position: absolute;
  left: 0%;
  width: 100%;
  height: 100%;
  top: 0%;
  perspective: 1000px;
  transform-style: preserve-3d;
}

.darkOne {
  top: 0%;
  background: #f7ac08;
  transform: rotateY(180deg) rotateX(225deg);
}

.lightOne {
  top: 0%;
  background: #f7e349;
  transform: rotateX(45deg);
}

.submarine__sail {
  position: absolute;
  top: 40%;
  left: 50%;
  width: 90px;
  height: 50px;
  transform: translate(-50%, -100%);
  background: #a63107;
  -webkit-clip-path: polygon(0% 0%, 70% 0%, 100% 100%, 0% 100%);
          clip-path: polygon(0% 0%, 70% 0%, 100% 100%, 0% 100%);
}

.submarine__sailshadow {
  position: absolute;
  width: 160%;
  height: 10%;
  background: #a12f05;
  border-radius: 5px;
}

.dark1 {
  left: 0%;
  top: 0%;
  transform: translate(0%, -33%);
}

.dark2 {
  left: 0%;
  top: 50%;
}

.light1 {
  left: 20%;
  top: 20%;
  width: 50%;
  background: #f56e07;
}

.submarine__window {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-image: linear-gradient(45deg, #c9e5d9, #fff);
  border: 8px solid #a12f05;
  z-index: 10;
  -webkit-animation: shadow-change 1s linear infinite;
          animation: shadow-change 1s linear infinite;
}

.one,
.two {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.one {
  left: 40%;
}

.two {
  left: 20%;
}

.submarine__shadowdark {
  position: absolute;
  left: 70%;
  top: 50%;
  width: 70px;
  height: 10px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background: #f56e07;
}

.submarine__shadowlight {
  position: absolute;
  left: 35%;
  top: 13%;
  width: 100px;
  height: 6px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background: #f56e07;
}

.submarine__shadowarcLight {
  position: absolute;
  top: 65%;
  left: 80%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #f56e07;
}

.submarine__periscope {
  position: absolute;
  top: 0%;
  left: 40%;
  width: 20px;
  height: 50px;
  border-right: 10px solid #a63107;
  border-top: 10px solid #db420b;
  border-left: 0px solid transparent;
  border-bottom: 0px solid transparent;
  border-top-right-radius: 10px;
}

.submarine__periscopeglass {
  position: absolute;
  left: 40%;
  top: 0%;
  width: 5px;
  height: 15px;
  background: #f7ac08;
  transform: translate(-50%, -15%);
}

.light {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 500px;
  height: 100px;
  background: linear-gradient(to left, #817E97, #0a0640);
  /* The points are: centered top, left bottom, right bottom */
  -webkit-clip-path: polygon(0% 0%, 50% 45%, 50% 55%, 0% 100%);
          clip-path: polygon(0% 0%, 50% 45%, 50% 55%, 0% 100%);
  transform: translate(-18%, -45%);
}

.bubbles__container {
  position: absolute;
  top: 25%;
  left: 55%;
  width: 100px;
  height: 50px;
  transform: translate(100%, -50%);
}

.bubbles {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  left: 5%;
  top: 5%;
  background: #fff;
  opacity: 0.8;
}

.bubble1 {
  top: 15%;
  left: 1%;
  opacity: 0;
  -webkit-animation: move-right 1s infinite linear;
          animation: move-right 1s infinite linear;
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}

.bubble2 {
  top: 30%;
  left: 1%;
  opacity: 0;
  -webkit-animation: move-right 1s infinite linear;
          animation: move-right 1s infinite linear;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

.bubble3 {
  top: 45%;
  left: 1%;
  opacity: 0;
  -webkit-animation: move-right 1s infinite linear;
          animation: move-right 1s infinite linear;
  -webkit-animation-delay: 0.75s;
          animation-delay: 0.75s;
}

.bubble4 {
  top: 60%;
  left: 1%;
  opacity: 0;
  -webkit-animation: move-right 1s infinite linear;
          animation: move-right 1s infinite linear;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}


@-webkit-keyframes shadow-change {
  0%, 100% {
    background-image: linear-gradient(45deg, #c9e5d9 0%, #c9e5d9 20%, #fff 21%, #fff 39%, #c9e5d9 40%, #fff 41%, #fff 59%, #c9e5d9 60%);
  }
  20% {
    background-image: linear-gradient(45deg, #c9e5d9 20%, #c9e5d9 40%, #fff 41%, #fff 59%, #c9e5d9 60%, #fff 61%, #fff 79%, #c9e5d9 80%);
  }
  40% {
    background-image: linear-gradient(45deg, #c9e5d9 40%, #c9e5d9 60%, #fff 61%, #fff 79%, #c9e5d9 80%, #fff 81%, #fff 99%, #c9e5d9 0%);
  }
  60% {
    background-image: linear-gradient(45deg, #c9e5d9 60%, #c9e5d9 80%, #fff 81%, #fff 99%, #c9e5d9 0%, #fff 1%, #fff 19%, #c9e5d9 20%);
  }
  80% {
    background-image: linear-gradient(45deg, #c9e5d9 80%, #c9e5d9 0%, #fff 1%, #fff 19%, #c9e5d9 20%, #fff 21%, #fff 39%, #c9e5d9 40%);
  }
}

@keyframes shadow-change {
  0%, 100% {
    background-image: linear-gradient(45deg, #c9e5d9 0%, #c9e5d9 20%, #fff 21%, #fff 39%, #c9e5d9 40%, #fff 41%, #fff 59%, #c9e5d9 60%);
  }
  20% {
    background-image: linear-gradient(45deg, #c9e5d9 20%, #c9e5d9 40%, #fff 41%, #fff 59%, #c9e5d9 60%, #fff 61%, #fff 79%, #c9e5d9 80%);
  }
  40% {
    background-image: linear-gradient(45deg, #c9e5d9 40%, #c9e5d9 60%, #fff 61%, #fff 79%, #c9e5d9 80%, #fff 81%, #fff 99%, #c9e5d9 0%);
  }
  60% {
    background-image: linear-gradient(45deg, #c9e5d9 60%, #c9e5d9 80%, #fff 81%, #fff 99%, #c9e5d9 0%, #fff 1%, #fff 19%, #c9e5d9 20%);
  }
  80% {
    background-image: linear-gradient(45deg, #c9e5d9 80%, #c9e5d9 0%, #fff 1%, #fff 19%, #c9e5d9 20%, #fff 21%, #fff 39%, #c9e5d9 40%);
  }
}
@-webkit-keyframes move-right {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.4;
    transform: translate(10%, 10%);
  }
  50% {
    opacity: 0.2;
    transform: translate(450%, 25%);
  }
  80% {
    opacity: 0;
    transform: translateX(555%);
  }
  100% {
    opacity: 0;
    left: 0%;
    top: 0%;
  }
}
@keyframes move-right {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.4;
    transform: translate(10%, 10%);
  }
  50% {
    opacity: 0.2;
    transform: translate(450%, 25%);
  }
  80% {
    opacity: 0;
    transform: translateX(555%);
  }
  100% {
    opacity: 0;
    left: 0%;
    top: 0%;
  }
}
@-webkit-keyframes rotateInfinite {
  0% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}
@keyframes rotateInfinite {
  0% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}




.CthulhuContainer {

}
.CthulhuImg {
  width: 50%;
}



@media (min-width: 768px) { 

  .Presentation {
    position: absoulute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 5rem;
  
  }
  .Titulo {
    font-size: 1.5rem;
  }

}

@media (min-width: 1600px) { 

  .Titulo {
    font-size: 2.5rem;
  }

}