.PreaboutContainer {
  height: 40vh;
  width: auto;
  background-color: #210002;
  position: relative;
  margin-top: -250px;
}

@media (min-width: 320px) and (max-width: 480px) { 

  .PreaboutContainer {
    height: 40vh;
    width: auto;
    background-color: #210002;
    position: relative;
    margin-top: -450px;
  }
}

@media (min-width: 481px) { 

  .PreaboutContainer {
    height: 40vh;
    width: auto;
    background-color: #210002;
    position: relative;
    margin-top: -170px;
  }
}

@media (min-width: 768px) { 

  .PreaboutContainer {
    height: 40vh;
    width: auto;
    background-color: #210002;
    position: relative;
    margin-top: -10px;
  }
}

@media (min-width: 1600px) { 

  .PreaboutContainer {
    height: 40vh;
    width: auto;
    background-color: #210002;
    position: relative;
    margin-top: -550px;
  }

}

