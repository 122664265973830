.ContainerGodzilla {
  height: 50vh; /* Use viewport height instead of percentage */
  width: 100%;
  background-color: #dcdcdc;
  position: relative;
}

.CloudGodzilla {
  position: absolute;
  left: 0;
  bottom: 0;
}

.MountainGodzilla {
  position: absolute;
  left: 0;
  bottom: 0; /* Adjust the bottom value to position the element */
  z-index: 1;
}

/* .TreesGodzilla {
  position: absolute;
  left: 0;
  bottom: 90%;
  z-index: 222;
} */

.TreeA {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
}
.TreeAAnimated {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
  animation: trees-shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) 16 both 0.3s; 
}

.TreeB {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
}
.TreeBAnimated {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  animation: trees-shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) 16 both 0.3s;
}

.Gojira {
  position: absolute;
  left: 50%; /* Position the element in the middle of the screen */
  bottom: 0%;
  /* transform: translateX(-20%); */
  z-index: 5;
}

.GojiraAnimated {
  position: absolute;
  left: 50%; /* Position the element in the middle of the screen */
  bottom: 0%;
  /* transform: translateX(-500%);
  will-change: transform; */
  z-index: 5;
  /* transform-origin: 10% 100%; */
  animation: godzilla-move 15s linear, 
  /* godzilla-step-fin 0.8s ease-in-out 15s forwards,
  godzilla-step 1s ease-in-out 15 both; */
}

/* Use percentage values for the width to make the elements responsive */
.ImgCloud {
  width: 30%;
}

.ImgMountain {
  width: 100%;
}

.ImgTreeA {
  width: 100%;
}

.ImgTreeB {
  width: 100%;
}

.ImgGojira {
  width: 70%;
}
.ImgGojiraAnimated {
  width: 70%;
  animation: godzilla-step-fin 0.8s ease-in-out 8s forwards,
             godzilla-step 1s ease-in-out 8 both;
}


.OlahulkContainer {
  position: absolute;
  bottom: -12%;
  z-index: 8;
  width: 100%;
}
.Olahulk {
  width: 100%;
}


@keyframes trees-shake {
  0% { transform: translate(0, 0); }
  10% { transform: translate(0, -2px); }
  20% { transform: translate(0, 2px); }
  30% { transform: translate(0, 0); }
  100% { transform: translate(0, 0); } }

@keyframes godzilla-move {
  0% { transform: translateX(-500px); }
  100% { transform: translateX(0); } }

@keyframes godzilla-step {
  0% { transform: translate(0, 0) rotate(0deg); }
  40% { transform: translate(0px, 25px) rotate(2deg); }
  50% { transform: translate(0px, 25px) rotate(2deg); }
  100% { transform: translate(0, 0) rotate(0deg); } }

@keyframes godzilla-step-fin {
  0% { transform: translate(44%, 0) rotate(0deg); }
  50% { transform: translate(44.25%, 10px) rotate(1deg); }
  100% { transform: translate(44%, 0) rotate(0deg); } }



  @media (min-width: 768px) {

    .ContainerGodzilla {
      height: 100vh; /* Use viewport height instead of percentage */
      width: 100%;
      background-color: #dcdcdc;
      position: relative;
    }

    .Gojira {
      position: absolute;
      left: 50%; /* Position the element in the middle of the screen */
      bottom: 2%;
      /* transform: translateX(-20%); */
      z-index: 5;
      transform-origin: 10% 100%;
    
    }
    
    .GojiraAnimated {
      position: absolute;
      left: 50%; /* Position the element in the middle of the screen */
      bottom: 2%;
      /* transform: translateX(-500%);
      will-change: transform; */
      z-index: 5;
      /* transform-origin: 10% 100%; */
      animation: godzilla-move 15s linear, 
      /* godzilla-step-fin 0.8s ease-in-out 15s forwards,
      godzilla-step 1s ease-in-out 15 both; */
    }

    .ImgGojiraAnimated {
      width: 70%;
      animation: godzilla-step-fin 0.8s ease-in-out 15s forwards,
                 godzilla-step 1s ease-in-out 15 both;
    }

    .OlahulkContainer {
      position: absolute;
      bottom: -20%;
      z-index: 8;
      width: 100%;
    }
    





  }