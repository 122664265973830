@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300;400;500;600&display=swap');

.bubbleContainer {
  position: absolute;
  /* padding-left: 10px;
  padding-right: 10px; */
  top: 0;
  left: 0;
  right: 0;
  height: 1rem;
  background: var(--footer-background);
  filter: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><defs><filter id="blob"><feGaussianBlur in="SourceGraphic" stdDeviation="2" result="blur" /><feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 20 -7" result="blob" /><feComposite in="SourceGraphic" in2="blob" operator="atop" /></filter></defs></svg>#blob');
}

.bubble {
  position: absolute;
  left: var(--blob-position, 50%);
  background: var(--footer-background);
  border-radius: 100%;
  transform: translate(-50%, 50%);
  animation: bubble-size var(--blob-time, 4s) ease-in infinite var(--blob-delay, 0s), bubble-move var(--blob-time, 4s) ease-in infinite var(--blob-delay, 0s);
}

.footer {
  --footer-background: #056aa3;
  display: grid;
  position: relative;
  grid-area: "footer";
  min-height: 8rem;
  z-index: 10;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  /* padding: 1rem 1rem; */
  background-color: var(--footer-background);
  z-index: 20;
}

.footertexts {
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly;
  align-items: center; */
}
.footersubtexts {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-left: 110px;
}

.FooterP {
  font-size: 1rem;
  font-family: 'Signika', sans-serif;
  font-weight: 300;
  color: #fff;
  /* align-text: center; */
  padding-left: 1rem;
  padding-right: 1rem;
}
.FooterPp {
  font-size: 1rem;
  font-family: 'Signika', sans-serif;
  font-weight: 500;
  color: #fff;
  /* align-text: center; */
  padding-left: 1rem;
  padding-right: 1rem;
}


@keyframes bubble-size {
  0%, 75% {
    width: var(--blob-size, 3rem);
    height: var(--blob-size, 3rem);
  }
  100% {
    width: 0rem;
    height: 0rem;
  }
}

@keyframes bubble-move {
  0% {
    bottom: -3rem;
  }
  100% {
    bottom: var(--blob-distance, 10rem);
  }
}

@media (min-width: 768px) { 
  .footersubtexts {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* gap: 100px; */
    padding-left: 200px;
  }
}

@media (min-width: 1600px) { 

  .FooterP {
    font-size: 1.25rem;
    font-family: 'Signika', sans-serif;
    font-weight: 300;
    color: #fff;
    /* align-text: center; */
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .FooterPp {
    font-size: 1.25rem;
    font-family: 'Signika', sans-serif;
    font-weight: 500;
    color: #fff;
    /* align-text: center; */
    padding-left: 1rem;
    padding-right: 1rem;
  }

}