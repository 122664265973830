.ContainerPreSolarsystem {
  height: 30vh;
  width: auto;
  background: transparent;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  margin-top: -56px;
}
.Separator4 {
  position: absolute;
  z-index: 90;
  margin-top: 55px;
}



@media (min-width: 320px) and (max-width: 480px) { 

}

@media (max-height: 740px) {
  .Separator4 {
    position: absolute;
    z-index: 90;
    margin-top: 0px;
  }
}

@media (min-width: 768px) { 
  
  .ContainerPreSolarsystem {
    height: 30vh;
    width: 100%;
    background: transparent;
    position: relative;
  }
}