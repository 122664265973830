.Separador1 {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 90;
}

.SeparatorEscImg {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 90;
}
