.parallaxContainer {
  position: relative;
  height: 160vh;
  /* overflow: hidden; */
  background-color: #ffaf00;
  /* background-image: url('./../../media/parallax/parallax0portfolio.png'); */
  background-size: cover;
  background-position: center;
}

.layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1000px;
  background-repeat: norepeat;
  background-size: auto 1038px;
  background-position: bottom center;
}

.layer0 {
  background-image: url('./../../media/parallax/parallax0portfolio6.png');
  z-index: 0;
}

.layer1 {
  background-image: url('./../../media/parallax/parallax1.png');
}

.layer2 {
  background-image: url('./../../media/parallax/parallax2portfolio.png');
}

.layer3 {
  background-image: url('./../../media/parallax/parallax3.png');
}

.layer4 {
  background-image: url('./../../media/parallax/parallax4.png');
}

.layer5 {
  background-image: url('./../../media/parallax/parallax5.png');
}

.layer6 {
  background-image: url('./../../media/parallax/parallax6.png');
}

.layer7 {
  background-image: url('./../../media/parallax/parallax7.png');
}

.layer8 {
  background-image: url('./../../media/parallax/parallax8.png');
}

.stars {
  width: 65%;
  position: absolute;
  /* perspective: 1000px; */
  /* z-index: 9; */
  /* transform: translateZ(0);
  transition: transform 350ms ease-out; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
  
}

@keyframes shine {
  0%  { box-shadow: 0 0 26px 4px #f29544;}
  25% { box-shadow: 0 0 38px 8px #f29544;}
  50% { box-shadow: 0 0 40px 12px #f29544;}
  75% { box-shadow: 0 0 52px 16px #f29544;}
  100% { box-shadow: 0 0 64px 20px #f29544;}
}


@media (min-width: 769px) {
  .stars {
    height: 50%;
  }
}
@media (max-width: 768px) {
  .stars {
    height: 25%;
  }
}

.stars .star {
  border-radius: 50%;
  position: absolute;
}
.stars .star.b {
  background: linear-gradient(150deg, #f57659, #f24e29);
  box-shadow: 0 0 16px 2px #f29544;
  animation: shine 10s ease-in-out alternate infinite;
}
@media (min-width: 769px) {
  .stars .star.b {
    width: 4rem;
    height: 4rem;
    top: 300px;
    /* top: calc(50% - 3rem); */
  }
}
@media (max-width: 768px) {
  .stars .star.b {
    width: 3rem;
    height: 3rem;
    top: 300px;
    /* top: calc(50% - 2rem); */
  }
}
.stars .star.a {
  background: linear-gradient(-15deg, #cfcfcf -66%, #fff);
  right: 50px;
  box-shadow: 0 0 16px 2px #f29544;
  animation: shine 10s ease-in-out alternate infinite;
}
@media (min-width: 769px) {
  .stars .star.a {
    width: 8rem;
    height: 8rem;
    top: 300px;
    /* top: calc(50% - 9rem); */
  }
}
@media (max-width: 768px) {
  .stars .star.a {
    width: 6rem;
    height: 6rem;
    top: 300px;
    /* top: calc(50% - 7rem); */
  }
}

@media (min-width: 1600px) { 
  .stars .star.a {
    width: 12rem;
    height: 12rem;
    top: 100px;
    /* top: calc(50% - 7rem); */
  }

  .stars .star.b {
    width: 6rem;
    height: 6rem;
    top: 200px;
    left: 700px;
    /* top: calc(50% - 2rem); */
  }
}

